import { Formik } from "formik";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import InputField from "../../components/inputFields/InputField";
import TextareaField from "../../components/inputFields/TextField";
import { useEffect, useState } from "react";
import { addServiceRequestCommentApi, getServiceRequestApi } from "../../services/apis/service_request";
import TimelineComponent from "../../components/CustomTimeline/TimelineComponent";
import { IoPencil } from "react-icons/io5";
import "../../constant/css/custom_styles.css";
import ServiceRequestCommentFormModal from "../../components/popups/fromModal/ServiceRequestCommentFormModel";
import StarRating from "../../components/StarRating";
import Toast, { showSuccessToast } from "../../components/toastify/Toast";
import showAlert from "../../components/errorBoundries/Errors";
import { get_user_id } from "../../constant/urls/localStoarege";

const DetailedServiceRequest = () => {
    const location = useLocation();
    const userId = get_user_id();
    const [rowData, setRowData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);


    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handlePopupSubmit = async (assignedUser, time, status, comment) => {
        if (assignedUser !== "") {
            setIsOpen(false);
            try {
                const payload = {
                    service_request: location.state.rowData.id,
                    assigned_user: assignedUser,
                    status: status,
                    comment: comment,
                    created_by: userId,
                };
                const response = await addServiceRequestCommentApi(payload);
                if (response?.status === 201) {
                    showSuccessToast("Comment added successfully!");
                    fetchData();
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } else {
            showAlert("Required", "Please select a user to assign", "warning");
        }
    };

    useEffect(() => {
        if (location.state.rowData?.image) {
            setSelectedImage(location.state.rowData?.image);
        }
        fetchData();
    }, [location.state.rowData]);

    const fetchData = async () => {
        if (location.state?.rowData?.id) {
            try {
                const response = await getServiceRequestApi(`?service_request_id=${location.state.rowData.id}`);
                setRowData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    return (
        <div className="pl-5">
            <Toast />
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <Link to='/' className="hover:underline">Home</Link>
                <IoIosArrowForward />
                <Link to='/service-request' className="hover:underline">Service Requests</Link>
                <IoIosArrowForward />
                <p>Details</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    enableReinitialize
                    initialValues={{
                        user_name: rowData?.user || "",
                        date: rowData?.created_at || "",
                        status: rowData?.status || "Pending",
                        description: rowData?.description || "",
                        phone: rowData?.phone || "",
                        title: rowData?.title || "",
                    }}
                >
                    {({ isSubmitting, resetForm }) => (
                        <form>
                            <div className="border border-gray-300 p-4 rounded-lg bg-gray-100">
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="User Name"
                                            name="user_name"
                                            type="text"
                                            placeholder="User Name"
                                            disable
                                        />
                                        <InputField
                                            label="Date"
                                            name="date"
                                            type="text"
                                            placeholder="DD-MM-YYYY"
                                            disable
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="Ticket Status"
                                            name="status"
                                            type="text"
                                            placeholder="Status"
                                            disable
                                        />
                                        <InputField
                                            label="Phone"
                                            name="phone"
                                            type="text"
                                            placeholder="XXXXXXXXXX"
                                            disable
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="w-full mb-1">
                                        <InputField
                                            label="Ticket Title"
                                            name="title"
                                            type="text"
                                            placeholder="Ticket Title"
                                            disable
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <TextareaField
                                        label="Ticket Description"
                                        name="description"
                                        placeholder="Description"
                                        disable
                                    />
                                </div>
                                <div className="w-[200px]">
                                    <div className="w-[200px] h-[200px] rounded-lg">
                                        <img
                                            src={selectedImage}
                                            alt="Profile"
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
            <div className="flex flex-row my-4">
                <p className="font-medium flex-1">Ticket Status</p>
                <div>
                    <button
                        type="button"
                        onClick={openModal}
                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
                    >
                        <IoPencil style={{ color: "white" }} size={18} />
                        Add New Comment
                    </button>
                </div>
            </div>
            <div className="border border-gray-300 p-4 rounded-lg bg-gray-100">
                <TimelineComponent data={rowData?.comments} />
            </div>
            <p className="font-medium my-4">Feedback</p>
            <div className="border flex flex-row border-gray-300 p-4 rounded-lg bg-gray-100">
                <StarRating rating={rowData?.feedback} />
            </div>

            <ServiceRequestCommentFormModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Add FAQ Type Modal"
                onSubmit={handlePopupSubmit}
            />
        </div>
    );
};

export default DetailedServiceRequest;
