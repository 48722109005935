import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InputField from "../../components/inputFields/InputField";
import Button from "../../components/buttons/Button";
import { get_user_id } from "../../constant/urls/localStoarege";
import { getDetailDevicesApi, getUserDropDownApi } from "../../services/apis/device";
import showAlert from "../../components/errorBoundries/Errors";
import { showSuccessToast } from "../../components/toastify/Toast";
import StyledHeadingText from "../../components/Fonts/StyledHeadingText";
import ExpandableImageComponent from "../../components/Device/ExpandableImageComponent";
import Select from "../../components/inputFields/SelectField";
import TextareaField from "../../components/inputFields/TextField";

function DetailedDevice() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const location = useLocation();
    const [rowData, setRowData] = useState({});
    const [userOptions, setUserOptions] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getUserDropDownApi();
                if (respones?.status === 200) {
                    setUserOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const path = location.pathname.split("/");
                const deviceId = path[path.length - 1];
                const respones = await getDetailDevicesApi(`?id=${deviceId}`);
                if (respones?.status === 200) {
                    setRowData(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, [location]);

    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };
    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <Link to='/' className="hover:underline">Home</Link>
                <IoIosArrowForward />
                <Link to='/device' className="hover:underline">Devices</Link>
                <IoIosArrowForward />
                <p>Device Details</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-4">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: rowData?.device_name,
                        date: rowData?.installation_date,
                        warranty: rowData?.warranty,
                        serial: rowData?.serial_no,
                        mac_id: rowData?.mac_id,
                        plant: rowData?.plant_name,
                        user: rowData?.user_name,
                        lat: rowData?.lat,
                        lon: rowData?.lon,
                        pump_pressure: rowData?.pump_max_pressure,
                        pump_temp: rowData?.pump_max_temp,
                        battery: rowData?.battery_min_val,
                        solar: rowData?.solar_min_val,
                        isActive: rowData?.is_active,
                        district: rowData?.district || "",
                        area: rowData?.area || "",
                        address: rowData?.address || "",
                        country: rowData?.country || "",
                        state: rowData?.state || "",
                        city: rowData?.city || "",
                        deviceType: rowData?.device_type || "",
                        simNumber: rowData?.sim_number || "",
                        simBrand: rowData?.sim_brand || "",
                        customerNumber: rowData?.customer_number || "",
                        pincode: rowData?.pincode || "",
                        setTemp: rowData?.set_temperaure || "",
                        setHum: rowData?.set_humidity || "",
                        setCrop: rowData?.set_crop || "",
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Please provide name"),
                        serial: Yup.string().required("Please provide serial"),
                        mac_id: Yup.string().required("Please provide Mac ID"),
                        plant: Yup.string().required("Please Select Plant"),
                        user: Yup.string().required("Please Select user"),
                        date: Yup.string().required("Please Provide installation date"),
                        warranty: Yup.string().required("Please Provide warranty period"),
                    })}
                >
                    {() => (
                        <Form>
                            <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                                <div className="w-full mb-1">
                                    <InputField
                                        disable={true}
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Device Name"
                                        required
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            disable={true}
                                            label="Serial No"
                                            name="serial"
                                            type="name"
                                            placeholder="Enter Serial No."
                                            required
                                        />
                                        <InputField
                                            disable={true}
                                            label="Mac ID"
                                            name="mac_id"
                                            type="text"
                                            placeholder="00-BC-MC-AB-CL-09"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            disable={true}
                                            label="Plant"
                                            name="plant_name"
                                            type="text"
                                            placeholder="00-BC-MC-AB-CL-09"
                                            required
                                        />
                                        <InputField
                                            disable={true}
                                            label="Pincode"
                                            name="pincode"
                                            type="number"
                                            placeholder="XXXXXX"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            disable={true}
                                            label="Latitude"
                                            name="lat"
                                            type="text"
                                            placeholder="12.23"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Longitude"
                                            name="lon"
                                            type="text"
                                            placeholder="23.32"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <TextareaField
                                            label="Address"
                                            name="address"
                                            type="text"
                                            placeholder="Enter your Addess"
                                            disable={true}
                                            false
                                        />
                                        <TextareaField
                                            label="Area"
                                            name="area"
                                            type="text"
                                            placeholder="Enter Area Here"
                                            disable={true}
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-3">
                                        <InputField
                                            disable={true}
                                            label="Coutry"
                                            name="country"
                                            type="name"
                                            placeholder="India"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="State"
                                            name="state"
                                            type="name"
                                            placeholder="Maharastra"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="District"
                                            name="district"
                                            type="name"
                                            placeholder="Pune"
                                            false
                                        />
                                        {/* <Select
                                            label="District"
                                            name="district"
                                            options={transformData()}
                                            false
                                        /> */}
                                        <InputField
                                            disable={true}
                                            label="City"
                                            name="city"
                                            type="name"
                                            placeholder="Pune"
                                            false
                                        />
                                        <Select
                                            label="Device Type"
                                            name="deviceType"
                                            disabled={true}
                                            options={[
                                                {
                                                    value: "Solar",
                                                    label: "Solar",
                                                },
                                                {
                                                    value: "Grid",
                                                    label: "Grid",
                                                },
                                                {
                                                    value: "Both",
                                                    label: "Both",
                                                },
                                            ]}
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-4">
                                        <InputField
                                            disable={true}
                                            label="Compressor (Max. Value)"
                                            name="pump_pressure"
                                            type="text"
                                            placeholder="12"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Compressor Temp. (Max. Value)"
                                            name="pump_temp"
                                            type="text"
                                            placeholder="35"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Battery Percentage (Min. Value)"
                                            name="battery"
                                            type="text"
                                            placeholder="10"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Solar Power Generation (Min Value)"
                                            name="solar"
                                            type="text"
                                            placeholder="500"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Set Temperature"
                                            name="setTemp"
                                            type="number"
                                            placeholder="52"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Set Humidity"
                                            name="setHum"
                                            type="number"
                                            placeholder="85"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Sim Number"
                                            name="simNumber"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Customer Number"
                                            name="customerNumber"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            false
                                        />
                                        <InputField
                                            disable={true}
                                            label="Sim Brand"
                                            name="simBrand"
                                            type="text"
                                            placeholder="VI/ Jio/ Airtel"
                                            false
                                        />
                                        {/* <Select
                                            label="Commodity"
                                            name="setCrop"
                                            options={transformCommodityData()}
                                            false
                                        /> */}
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <InputField
                                        disable={true}
                                        label="User"
                                        name="user_name"
                                        type="text"
                                        placeholder="User Name"
                                        required
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            disable={true}
                                            label="Installation Date"
                                            name="date"
                                            type="date"
                                            placeholder="DD-MM-YYYY"
                                            required
                                        />
                                        <InputField
                                            disable={true}
                                            label="Warranty Period (Years)"
                                            name="warranty"
                                            type="number"
                                            placeholder="5"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="flex flex-row items-center my-4">
                    <div className="flex-1">
                        <StyledHeadingText size={14} text={"Uploaded Images"} />
                    </div>
                    <div>
                        <Button
                            type="submit"
                            className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-sm w-full px-8 py-1.5 text-center h-[45]"
                        >
                            {"+ Add Image"}
                        </Button>
                    </div>
                </div>
                <div className="grid gap-2 md:grid-cols-8">
                    <ExpandableImageComponent imageUrl={"https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-169994-674010.jpg&fm=jpg"} />
                    <ExpandableImageComponent imageUrl={"https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"} />
                    <ExpandableImageComponent imageUrl={"https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1723593600&semt=ais_hybrid"} />
                    <ExpandableImageComponent imageUrl={"https://as2.ftcdn.net/v2/jpg/06/41/42/69/1000_F_641426931_sJkCqdIkiI5GPtbBQ92S7xIJk9akRo33.jpg"} />
                </div>
            </div>
            {/* Form End */}
        </div >
    );
}

export default DetailedDevice;