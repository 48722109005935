import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/inputFields/InputField";
import Select from "../../components/inputFields/SelectField";
import Button from "../../components/buttons/Button";
import { get_user_id } from "../../constant/urls/localStoarege";
import { addDeviceApi, getPlantDropDownApi, getUserDropDownApi } from "../../services/apis/device";
import showAlert from "../../components/errorBoundries/Errors";
import { showSuccessToast } from "../../components/toastify/Toast";
import TextareaField from "../../components/inputFields/TextField";

function AddDevice() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [plantOptions, setPlantOptions] = useState({});
    const [userOptions, setUserOptions] = useState({});
    const [districtOptions, setDistrictOptions] = useState({});
    // const [commodityOptions, setCommodityOptions] = useState({});



    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getUserDropDownApi();
                if (respones?.status === 200) {
                    setUserOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);


    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getPlantDropDownApi();
                if (respones?.status === 200) {
                    setPlantOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);

    const transformPlantData = () => {
        const options = [];
        if (plantOptions && plantOptions) {
            for (let i = 0; i < plantOptions.length; i++) {
                const item = plantOptions[i];
                options.push({
                    value: item.id,
                    label: item.plant_name,
                });
            }
        }
        return options;
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileClick = () => {
        document.getElementById("fileInput").click();
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            formData.append("image", selectedFile);
            formData.append("plant", values.plant);
            formData.append("user", values.user);
            formData.append("device_name", values.name);
            formData.append("serial_no", values.serial);
            formData.append("mac_id", values.mac_id);
            formData.append("pincode", values.pincode);
            formData.append("lat", values.lat);
            formData.append("lon", values.lon);
            formData.append("address", values.address);
            formData.append("area", values.area);
            formData.append("city", values.city);
            formData.append("state", values.state);
            formData.append("country", values.country);
            formData.append("device_type", values.device_type);
            formData.append("set_temperaure", values.setTemp ?? "");
            formData.append("set_humidity", values.setHum ?? "");
            formData.append("sim_number", values.simNumber ?? "");
            formData.append("customer_number", values.customerNumber ?? "");
            formData.append("sim_brand", values.simBrand ?? "");
            formData.append("installation_date", values.date ?? "");
            formData.append("warranty", values.warranty ?? "");
            formData.append("pump_max_pressure", values.pump_pressure ?? "");
            formData.append("pump_max_temp", values.pump_temp ?? "");
            formData.append("battery_min_val", values.battery ?? "");
            formData.append("solar_min_val", values.solar ?? "");
            formData.append("is_active", values.isActive);
            formData.append("created_by", userId);

            const response = await addDeviceApi(formData);

            if (response?.status === 201) {
                showSuccessToast("Commodity created successfully!");

                navigate("/device");

                if (typeof resetForm === "function") {
                    resetForm();
                }
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.error ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
        setSubmitting(false);
    };

    const handleDiscardChanges = (resetForm) => {
        resetForm();
        navigate("/device");
    };

    const transformData = () => {
        const options = [];
        if (districtOptions && districtOptions) {
            for (let i = 0; i < districtOptions.length; i++) {
                const item = districtOptions[i];
                options.push({
                    value: item.id,
                    label: item.device_name,
                });
            }
        }
        return options;
    };

    // const transformCommodityData = () => {
    //     const options = [];
    //     if (commodityOptions && commodityOptions) {
    //         for (let i = 0; i < commodityOptions.length; i++) {
    //             const item = commodityOptions[i];
    //             options.push({
    //                 value: item.id,
    //                 label: item.name,
    //             });
    //         }
    //     }
    //     return options;
    // };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <Link to='/' className="hover:underline">Home</Link>
                <IoIosArrowForward />
                <Link to='/device' className="hover:underline">Devices</Link>
                <IoIosArrowForward />
                <p>Add Device</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    initialValues={{
                        name: "",
                        date: "",
                        warranty: "",
                        serial: "",
                        mac_id: "",
                        plant: "",
                        lat: "",
                        lon: "",
                        pump_pressure: "",
                        pump_temp: "",
                        battery: "",
                        solar: "",
                        isActive: true,
                        district: "",
                        area: "",
                        address: "",
                        country: "",
                        state: "",
                        city: "",
                        deviceType: "",
                        simNumber: "",
                        simBrand: "",
                        customerNumber: "",
                        setTemp: "",
                        setHum: "",
                        setCrop: "",
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Please provide name"),
                        serial: Yup.string().required("Please provide serial"),
                        plant: Yup.string().required("Please Select Plant"),
                        user: Yup.string().required("Please Select user"),
                        date: Yup.string().required("Please Provide installation date"),
                        warranty: Yup.string().required("Please Provide warranty period"),
                    })}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                                <div className="w-[200px]">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={handleImageChange}
                                    />
                                    <div
                                        onClick={handleProfileClick}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {selectedImage ? (
                                            <div className="w-[200px] h-[200px] rounded-lg">
                                                <img
                                                    src={selectedImage}
                                                    alt="Profile"
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            </div>
                                        ) : (
                                            <div className="border-dashed border-2 border-gray-300 rounded-lg w-[200px] h-[200px] flex flex-col justify-center items-center bg-[#ffffff]">
                                                <PiUploadFill size={35} className="text-gray-400" />
                                                <p className="text-gray-400 font-medium ">
                                                    Click to upload Image
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <InputField
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Device Name"
                                        required
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            label="Serial No"
                                            name="serial"
                                            type="name"
                                            placeholder="Enter Serial No."
                                            required
                                        />
                                        <InputField
                                            label="Mac ID"
                                            name="mac_id"
                                            type="text"
                                            placeholder="00-BC-MC-AB-CL-09"
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <div className="w-fill flex flex-row">
                                            <div className="flex-1">
                                                <Select
                                                    label="Plant"
                                                    name="plant"
                                                    options={transformPlantData()}
                                                    required
                                                    needSearch={true}
                                                />
                                            </div>
                                            <div className="flex flex-col justify-between mb-2">
                                                <div></div>
                                                <Link to="/plant/add" className="ml-4">
                                                    <button
                                                        type="button"
                                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
                                                    >
                                                        <IoMdAdd style={{ color: "white" }} size={23} />
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <InputField
                                            label="Pincode"
                                            name="pincode"
                                            type="number"
                                            placeholder="XXXXXX"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            label="Latitude"
                                            name="lat"
                                            placeholder="12.23"
                                            false
                                        />
                                        <InputField
                                            label="Longitude"
                                            name="lon"
                                            placeholder="23.32"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <TextareaField
                                            label="Address"
                                            name="address"
                                            type="text"
                                            placeholder="Enter your Addess"
                                            false
                                        />
                                        <TextareaField
                                            label="Area"
                                            name="area"
                                            type="text"
                                            placeholder="Enter Area Here"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-3">
                                        <InputField
                                            label="Coutry"
                                            name="country"
                                            type="name"
                                            placeholder="India"
                                            false
                                        />
                                        <InputField
                                            label="State"
                                            name="state"
                                            type="name"
                                            placeholder="Maharastra"
                                            false
                                        />
                                        <InputField
                                            label="District"
                                            name="district"
                                            type="name"
                                            placeholder="Pune"
                                            false
                                        />
                                        {/* <Select
                                            label="District"
                                            name="district"
                                            options={transformData()}
                                            false
                                        /> */}
                                        <InputField
                                            label="City"
                                            name="city"
                                            type="name"
                                            placeholder="Pune"
                                            false
                                        />
                                        <Select
                                            label="Device Type"
                                            name="deviceType"
                                            options={[
                                                {
                                                    value: "Solar",
                                                    label: "Solar",
                                                },
                                                {
                                                    value: "Grid",
                                                    label: "Grid",
                                                },
                                                {
                                                    value: "Both",
                                                    label: "Both",
                                                },
                                            ]}
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-4">
                                        <InputField
                                            label="Compressor (Max. Value)"
                                            name="pump_pressure"
                                            type="number"
                                            placeholder="12"
                                            false
                                        />
                                        <InputField
                                            label="Compressor Temp. (Max. Value)"
                                            name="pump_temp"
                                            type="number"
                                            placeholder="35"
                                            false
                                        />
                                        <InputField
                                            label="Battery Percentage (Min. Value)"
                                            name="battery"
                                            type="number"
                                            placeholder="10"
                                            false
                                        />
                                        <InputField
                                            label="Solar Power Generation (Min Value)"
                                            name="solar"
                                            type="number"
                                            placeholder="500"
                                            false
                                        />
                                        <InputField
                                            label="Set Temperature"
                                            name="setTemp"
                                            type="number"
                                            placeholder="52"
                                            false
                                        />
                                        <InputField
                                            label="Set Humidity"
                                            name="setHum"
                                            type="number"
                                            placeholder="85"
                                            false
                                        />
                                        <InputField
                                            label="Sim Number"
                                            name="simNumber"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            false
                                        />
                                        <InputField
                                            label="Customer Number"
                                            name="customerNumber"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            false
                                        />
                                        <InputField
                                            label="Sim Brand"
                                            name="simBrand"
                                            type="name"
                                            placeholder="VI/ Jio/ Airtel"
                                            false
                                        />
                                        {/* <Select
                                            label="Commodity"
                                            name="setCrop"
                                            options={transformCommodityData()}
                                            false
                                        /> */}
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <Select
                                        label="User"
                                        name="user"
                                        options={transformUserData()}
                                        required
                                        needSearch={true}
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <InputField
                                            label="Installation Date"
                                            name="date"
                                            type="date"
                                            placeholder="DD-MM-YYYY"
                                            required
                                        />
                                        <InputField
                                            label="Warranty Period (Years)"
                                            name="warranty"
                                            type="number"
                                            placeholder="5"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mb-6 flex items-center mt-4 pl-3 custom-checkbox">
                                <Field type="checkbox" name="isActive" className="mr-2 h-16" />
                                <div className="flex flex-col">
                                    <label htmlFor="isActive" className="text-base text-black">Enabled Device</label>
                                    <p className="text-sm text-gray-700">Select for Enable Device</p>
                                </div>
                            </div>
                            <div className="pb-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="button"
                                        className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                        onClick={() => handleDiscardChanges(resetForm)}
                                        disabled={isSubmitting}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default AddDevice;