import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/inputFields/InputField";
import TextareaField from "../../components/inputFields/TextField";
import Select from "../../components/inputFields/SelectField";
import Button from "../../components/buttons/Button";
import { get_user_id } from "../../constant/urls/localStoarege";
import showAlert from "../../components/errorBoundries/Errors";
import { editUsersApi, getPlantsApi, getPlantsDetailApi, getUserDropDownApi } from "../../services/apis/plant";
import { showSuccessToast } from "../../components/toastify/Toast";

function EditPlant() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const location = useLocation();
    const [rowData, setRowData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [userOptions, setUserOptions] = useState({});
    const [districtOptions, setDistrictOptions] = useState({});


    useEffect(() => {
        if (location.state.rowData?.image) {
            setSelectedImage(`${location.state.rowData?.image}`);
        }
        const fetchData = async () => {
            try {
                const respones = await getPlantsDetailApi(`?plant_id=${location.state.rowData?.id}`);
                if (respones?.status === 200) {
                    setRowData(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, [location.state.rowData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getUserDropDownApi();
                if (respones?.status === 200) {
                    setUserOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);

    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileClick = () => {
        document.getElementById("fileInput").click();
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append("image", selectedFile);
            }
            formData.append("phone", values.phone);
            formData.append("plant_name", values.name);
            formData.append("address", values.address);
            formData.append("city", values.city);
            formData.append("state", values.state);
            formData.append("district", values.district);
            formData.append("country", values.country);
            formData.append("pincode", values.pincode);
            formData.append("lat", values.lat);
            formData.append("lon", values.lon);
            formData.append("user", values.user);
            formData.append("installation_date", values.installationDate);
            formData.append("plant_email", values.email);
            formData.append("area", values.area);
            formData.append("created_by", userId);

            const response = await editUsersApi(formData, rowData?.id);

            if (response?.status === 200) {
                navigate("/plant");
                showSuccessToast("Plant Updated successfully!");
                if (typeof resetForm === "function") {
                    resetForm();
                }
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.error ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
        setSubmitting(false);
    };

    const handleDiscardChanges = (resetForm) => {
        resetForm();
        navigate("/plant");
    };

    const transformData = () => {
        const options = [];
        if (districtOptions && districtOptions) {
            for (let i = 0; i < districtOptions.length; i++) {
                const item = districtOptions[i];
                options.push({
                    value: item.id,
                    label: item.device_name,
                });
            }
        }
        return options;
    };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <Link to='/' className="hover:underline">Home</Link>
                <IoIosArrowForward />
                <Link to='/plant' className="hover:underline">Plants</Link>
                <IoIosArrowForward />
                <p>Edit Plant</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: rowData?.plant_name || "",
                        user: rowData?.user || "",
                        address: rowData?.address || "",
                        country: rowData?.country || "",
                        state: rowData?.state || "",
                        city: rowData?.city || "",
                        phone: rowData?.phone || "",
                        pincode: rowData?.pincode || "",
                        lat: rowData?.lat || "",
                        lon: rowData?.lon || "",
                        installationDate: rowData?.installation_date || "",
                        email: rowData?.plant_email || "",
                        district: rowData?.district || "",
                        area: rowData?.area || "",
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Please provide name"),
                        user: Yup.string().required("Select User"),
                        // country: Yup.string().required("Please enter Country"),
                        // state: Yup.string().required("Please enter State"),
                        // city: Yup.string().required("Please enter City"),
                        // phone: Yup.string().required("Please enter Phone"),
                        // pincode: Yup.string().required("Please enter Pincode"),
                        lat: Yup.string().required("Please enter Latitude"),
                        lon: Yup.string().required("Please enter Longitude"),
                    })}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="flex flex-row items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="w-[200px]">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={handleImageChange}
                                    />
                                    <div
                                        onClick={handleProfileClick}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {selectedImage ? (
                                            <div className="w-[200px] h-[200px] rounded-lg">
                                                <img
                                                    src={selectedImage}
                                                    alt="Profile"
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            </div>
                                        ) : (
                                            <div className="border-dashed border-2 border-gray-300 rounded-lg w-[200px] h-[200px] flex flex-col justify-center items-center bg-[#ffffff]">
                                                <PiUploadFill size={35} className="text-gray-400" />
                                                <p className="text-gray-400 font-medium ">
                                                    Click to upload Image
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-1 ml-5">
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-3">
                                            <InputField
                                                label="Name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Plant Name"
                                                required
                                            />
                                            <InputField
                                                label="Email"
                                                name="email"
                                                type="email"
                                                placeholder="xyz@example.com"
                                            />
                                            <div className="w-fill flex flex-row">
                                                <div className="flex-1">
                                                    <Select
                                                        label="User"
                                                        name="user"
                                                        options={transformUserData()}
                                                        needSearch={true}
                                                        required
                                                    />
                                                </div>
                                                <div className="flex flex-col justify-between mb-2">
                                                    <div></div>
                                                    <Link to="/users/add" className="ml-4">
                                                        <button
                                                            type="button"
                                                            className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
                                                        >
                                                            <IoMdAdd style={{ color: "white" }} size={23} />
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-2">
                                            <TextareaField
                                                label="Address"
                                                name="address"
                                                type="text"
                                                placeholder="Enter your Addess"
                                                false
                                            />
                                            <TextareaField
                                                label="Area"
                                                name="area"
                                                type="text"
                                                placeholder="Enter Area Here"
                                                false
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-3">
                                            <InputField
                                                label="Coutry"
                                                name="country"
                                                type="name"
                                                placeholder="India"
                                                false
                                            />
                                            <InputField
                                                label="State"
                                                name="state"
                                                type="name"
                                                placeholder="Maharastra"
                                                false
                                            />
                                            <InputField
                                                label="District"
                                                name="district"
                                                type="name"
                                                placeholder="Pune"
                                                false
                                            />
                                            {/* <Select
                                                label="District"
                                                name="district"
                                                options={transformData()}
                                                false
                                            /> */}
                                            <InputField
                                                label="City"
                                                name="city"
                                                type="name"
                                                placeholder="Pune"
                                                false
                                            />
                                            <InputField
                                                label="Date Of Installation"
                                                name="installationDate"
                                                type="date"
                                                placeholder="Enter Date"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-2">
                                            <InputField
                                                label="Phone No."
                                                name="phone"
                                                type="number"
                                                placeholder="XXXXXXXXXX"
                                                false
                                            />
                                            <InputField
                                                label="Pincode"
                                                name="pincode"
                                                type="number"
                                                placeholder="XXXXXX"
                                                false
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-2">
                                            <InputField
                                                label="Latitude"
                                                name="lat"
                                                type="number"
                                                placeholder="12.23"
                                                required
                                            />
                                            <InputField
                                                label="Longitude"
                                                name="lon"
                                                type="number"
                                                placeholder="23.32"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="py-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="button"
                                        className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                        onClick={() => handleDiscardChanges(resetForm)}
                                        disabled={isSubmitting}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default EditPlant;